/* eslint react/prop-types: 0 */
import React from 'react';
import Layout from 'components/layout';
import Head from 'components/head';
import Title from 'components/title';
import IO from 'components/io';
import { graphql } from 'gatsby';
import {
  ExampleContainer,
  ExampleCard,
  ExampleTitle,
  ExampleDescription,
  ExampleDate,
  ExampleLink,
  PageContainer,
} from 'components/example/examples.css';

const Examples = ({
  data: {
    allMarkdownRemark: { edges },
  },
}) => {
  const Examples = edges.map(edge => (
    <IO key={edge.node.id} rootMargin="-65px">
      {({ isVisible }) => (
        <ExampleLink
          isVisible={isVisible}
          to={edge.node.frontmatter.path}
          key={edge.node.id}
        >
          <ExampleCard isVisible={isVisible} key={edge.node.id}>
            <ExampleTitle>{edge.node.frontmatter.title}</ExampleTitle>
            <ExampleDescription>
              {edge.node.frontmatter.description
                ? edge.node.frontmatter.description
                : ''}
            </ExampleDescription>
            <ExampleDate>{edge.node.frontmatter.date}</ExampleDate>
          </ExampleCard>
        </ExampleLink>
      )}
    </IO>
  ));
  return (
    <Layout>
      <Head pageTitle="Examples" />
      <PageContainer>
        <Title as="h1" size="large">
          Examples
        </Title>
        <ExampleContainer>{Examples}</ExampleContainer>
      </PageContainer>
    </Layout>
  );
};

export default Examples;

export const pageQuery = graphql`
  query {
    allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/content/examples/" } }
      sort: { order: DESC, fields: [frontmatter___date] }
    ) {
      edges {
        node {
          id
          frontmatter {
            date(formatString: "DD MMM YYYY")
            path
            title
            description
            featuredImage {
              childImageSharp {
                fluid(maxWidth: 700, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`;
