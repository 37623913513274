import styled from 'styled-components';
import { Link } from 'gatsby';

export const PageContainer = styled.div`
  max-width: 1400px;
  padding: 0 2rem 1rem 2rem;
  margin: auto;
`;

export const ExampleLink = styled(Link)`
  text-decoration: none;
  color: inherit;
  transition: opacity 0.5s ease;
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};

  @media (max-width: 620px) {
    margin: auto;
  }
`;

export const ExampleContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding-bottom: 20px;
`;

export const ExampleCard = styled.div`
  box-sizing: border-box;
  width: 275px;
  height: 250px;
  background-color: #f8f8f8;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 2px;
  transition: all 0.3s ease;
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  margin: 20px;

  &:active,
  &:focus {
    outline: none;
  }

  &:hover {
    cursor: pointer;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
    transform: translateY(-1px);
  }
`;

export const ExampleTitle = styled.h1`
  font-size: 1.2em;
  line-height: 1.5;
  letter-spacing: 0.3px;
  margin-bottom: 20px;
`;

export const ExampleDescription = styled.p`
  color: #6d6d6d;
  line-height: 1.2;
`;

export const ExampleDate = styled.p`
  color: #acacaf;
  font-weight: 600;
  align-self: flex-end;
  margin-top: auto;
  margin-bottom: 0;
`;
